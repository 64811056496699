<template>
  <div class="structure-node">
    <div class="block block-rounded" :class="{ 'bg-primary text-white': node._id === entityId }">
      <div class="block-content block-content-full">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6">
            <strong>{{ node.legalName }}</strong
            ><br />
            <small>{{ node.companyNumber }}</small>
          </div>
          <div class="col-md-6 text-right">
            <button class="btn btn-light btn-sm mr-2" @click="onClickShowAssets">Show assets</button>
            <router-link class="btn btn-light btn-sm" :to="{ name: 'entity-structure', params: { id: node._id } }">View entity</router-link>
          </div>
        </div>
      </div>
      <div v-if="showAssets" class="block-content bg-body-light">
        <Spinner v-if="loadingAssets" />
        <ul v-else class="list-unstyled">
          <li v-for="asset in assets.data" :key="asset._id" class="font-size-sm font-w600 mb-1">
            <router-link :to="{ name: 'asset-overview', params: { id: asset._id } }">{{ asset.siteName }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="node.children && node.children.length > 0">
      <EntityStructureNode v-for="(child, index) in node.children" :key="index" :node="child" :entity-id="entityId" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import EntityStructureNode from './EntityStructureNode';
import Spinner from './Spinner';

export default {
  name: 'EntityStructureNode',
  components: {
    EntityStructureNode,
    Spinner
  },
  props: {
    node: {
      type: null,
      required: true
    },
    entityId: {
      type: null,
      required: true
    }
  },
  data() {
    return {
      loadingAssets: true,
      showAssets: false,
      assets: {
        data: [],
        total: 0
      }
    };
  },
  methods: {
    ...mapActions({
      listAssets: 'asset/list'
    }),
    async onClickShowAssets() {
      this.loadingAssets = true;
      this.showAssets = true;

      this.assets = await this.listAssets({ data: { params: { entityId: this.node._id } } }, true);

      this.loadingAssets = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.structure-node {
  margin: 0 0 0 30px;
  flex-grow: 1;
  flex-basis: 0;
}
</style>
