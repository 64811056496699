var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content content-full content-boxed"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('EntityStructureNode', {
    key: _vm.index,
    attrs: {
      "node": _vm.structure,
      "entity-id": _vm.entity._id
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }