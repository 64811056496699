<template>
  <div class="content content-full content-boxed">
    <div class="row justify-content-center">
      <EntityStructureNode :key="index" :node="structure" :entity-id="entity._id" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import EntityStructureNode from '@/components/EntityStructureNode';

export default {
  name: 'EntityStructure',
  components: {
    EntityStructureNode
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      entity: 'entity/entity',
      loading: 'entity/loadingAction',
      structure: 'entity/structure'
    })
  },
  created() {
    this.getStructure({ id: this.entity._id });
  },
  methods: {
    ...mapActions({
      getStructure: 'entity/structure'
    })
  }
};
</script>
