var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "structure-node"
  }, [_c('div', {
    staticClass: "block block-rounded",
    class: {
      'bg-primary text-white': _vm.node._id === _vm.entityId
    }
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('div', {
    staticClass: "row justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('strong', [_vm._v(_vm._s(_vm.node.legalName))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.node.companyNumber))])]), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('button', {
    staticClass: "btn btn-light btn-sm mr-2",
    on: {
      "click": _vm.onClickShowAssets
    }
  }, [_vm._v("Show assets")]), _c('router-link', {
    staticClass: "btn btn-light btn-sm",
    attrs: {
      "to": {
        name: 'entity-structure',
        params: {
          id: _vm.node._id
        }
      }
    }
  }, [_vm._v("View entity")])], 1)])]), _vm.showAssets ? _c('div', {
    staticClass: "block-content bg-body-light"
  }, [_vm.loadingAssets ? _c('Spinner') : _c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.assets.data, function (asset) {
    return _c('li', {
      key: asset._id,
      staticClass: "font-size-sm font-w600 mb-1"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'asset-overview',
          params: {
            id: asset._id
          }
        }
      }
    }, [_vm._v(_vm._s(asset.siteName))])], 1);
  }), 0)], 1) : _vm._e()]), _vm.node.children && _vm.node.children.length > 0 ? _c('div', _vm._l(_vm.node.children, function (child, index) {
    return _c('EntityStructureNode', {
      key: index,
      attrs: {
        "node": child,
        "entity-id": _vm.entityId
      }
    });
  }), 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }